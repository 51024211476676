import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  forwardRef,
  HostListener,
  input,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-input-text',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './input-text.component.html',
  styleUrl: './input-text.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent implements ControlValueAccessor {

  public label = input<string | null>(null);
  public placeholder = input<string | null>(null);
  public isPassword = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  private onChange: (value: string | null) => void = () => {};
  private onTouch = () => {};
  private isDisabled: boolean = false;

  public value: string | null = null;
  public isVisible: boolean = false;

  constructor() {}

  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    if (this.isVisible) {
      this.isVisible = false;
    }
  }

  valueChange() {
    this.onTouch();
    this.onChange(this.value);
  }

  writeValue(value: string | null) {
      this.value = value;
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  togglePasswordVisibility() {
    this.isVisible = !this.isVisible;
  }

  preventDrag(event: DragEvent) {
    event.preventDefault();
  }
}
